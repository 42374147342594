<template>
  <div>
    <v-card flat>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">APPROVE REQUEST FOR PRINT THE CONTRACT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Date Requested</th>
              <th class="text-center text-uppercase">FSC#</th>
              <th class="text-center text-uppercase">Name</th>
              <th class="text-center text-uppercase">Status</th>
              <th class="text-center text-uppercase">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in requested_for_print" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.date }}
              </td>
              <td class="text-center">
                {{ item.deceased.fsc_no}}
              </td>
              <td class="text-center">
                {{ item.deceased.last_name+', '+ item.deceased.first_name}}
              </td>
              <td class="text-center">
                {{ item.is_approved===0?'Not yet approve':'Approved' }}
              </td>
              <td class="text-center">
                <div
                  v-if="item.is_approved===0">
                  <v-icon
                    v-if="!is_deleting"
                    class="mr-2"
                    color="success"
                    @click="approved(requested_for_print[requested_for_print.map(function(x) {return x.id; }).indexOf(item.id)])"
                  >
                    {{icons.mdiCheck}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiInformationOutline,
    mdiAccountCashOutline,
    mdiCash,
    mdiCellphone,
    mdiClipboardClockOutline,
    mdiContentSaveMoveOutline,
    mdiCashRefund,
    mdiShieldAccount,
    mdiGoogleCirclesCommunities,
    mdiBallotOutline,
    mdiAccountCash,
    mdiCheck,
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from "moment";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
          mdiAccountCash,
          mdiCheck,
        },
      }
    },
    data() {
      return {
        id: 0,
        id_no: '',
        name: '',

        saving: false,
        alert: false,
        alert_message: '',

        search: '',
        search_member: '',
        search_items: [],

        requested_for_print: [],
        is_deleting: false,
      }
    },
    created() {
      this.initialize_data()
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'branch',
        'branch_id',
        'year',
        'month_start',
        'month_end',
        'month_of',
        'user_id',
        'branch_address',
        'branch_contact_no',
      ]),
    },
    methods: {
      ...mapActions('deceased_client', ['search_deceased_client']),
      ...mapActions('request_for_print_contract', ['approved_requested', 'get_requested']),
      ...mapActions('system_data', ['change_snackbar']),
      initialize_data() {
        this.get_requested({
          branch_id: this.branch_id,
        }).then(response => {
          this.requested_for_print = response.data
        })
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''
        this.is_deleting = false
        this.initialize_data()
      },
      approved(datas) {
        this.is_deleting = true
        const data = new FormData()
          data.append('id', datas.id)
          this.approved_requested(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
      }
    },
  }
</script>
